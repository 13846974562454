import PropTypes from "prop-types"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import classNames from "classnames/bind"
import { MergeTag } from "@ninetailed/experience.js-react"

// ---------------------------------------------------------

import { getUrlAndType, hasDarkBackground } from "@lib/utils"

import Button from "@components/button"
import BulletList from "@components/bullet-list"
import Image from "@components/image"
import GoogleMap from "@components/google-map"
import NewsLetterModule from "@components/forms/newsletter-signup"
import Podcast from "@components/podcast"
import Video from "@components/video"

// ---------------------------------------------------------

import { transformButton, transformImage } from "./transformer"
import styles from "./styles.module.scss"

// ---------------------------------------------------------

const ContentBlock = (props) => {
  const { anchorId, backgroundColor, className, content, isRounded } = props

  if (!content) return <div></div>

  const darkBackground = backgroundColor && hasDarkBackground(backgroundColor)

  const classes = classNames(className, {
    [`background_${backgroundColor}`]: backgroundColor,
    [styles.is_rounded]: isRounded,
    [styles.has_bg_color]: backgroundColor && backgroundColor !== "white",
    [styles.font_theme_light]: backgroundColor && darkBackground,
    [styles.font_theme_dark]: backgroundColor && !darkBackground
  })

  const assetMap = new Map()
  const entryMap = new Map()

  if (content.links?.assets) {
    // create an asset map
    // loop through the assets and add them to the map
    for (const asset of content.links.assets.block) {
      assetMap.set(asset.sys.id, asset)
    }
  }

  if (content.links?.entries) {
    for (const entry of content.links.entries.block) {
      entryMap.set(entry?.sys?.id, entry)
    }
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        let text = ""
        for (var i = 0; i < node.content?.length; i++) {
          text = text + node.content[i]?.value
        }

        const { url, isExternal } = getUrlAndType(node?.data?.uri)

        const mark = node.content[0]?.marks[0]?.type // determine font style, or mark
        return (
          // eslint-disable-next-line
          <a
            href={url}
            target={isExternal ? "_blank" : "_self"}
            rel={isExternal ? "noreferrer" : ""}
            className={classNames([styles[`${mark}`]])}
          >
            {text}
          </a>
        )
      },

      [BLOCKS.QUOTE]: (node, children) => {
        return <div className={styles.callout}>{children}</div>
      },

      [BLOCKS.TABLE_ROW]: (node, children) => {
        if (children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)) {
          // all children are header cells, so we should wrap the row
          // with a <thead /> tag
          return (
            <thead>
              <tr>{children}</tr>
            </thead>
          )
        } else {
          // not a header row, so we can render an ordinary <tr />
          return <tr>{children}</tr>
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id)

        // render the asset accordingly
        return (
          <div className={styles.image_container}>
            <Image
              src={asset.url || asset.src}
              alt={asset.description || asset.alt}
              width={asset.width}
              height={asset.height}
            />
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = entryMap.get(node.data.target.sys.id)
        if (entry?.__typename === "Button") {
          return <Button className={styles.spread} {...transformButton(entry)} />
        } else if (entry?.__typename === "Image") {
          return <Image {...transformImage(entry)} sizes="1440px" /> // eslint-disable-line
        } else if (entry?.__typename === "Form") {
          return (
            <NewsLetterModule
              theme="gray-background"
              formId={entry.id}
              redirectUrl={entry.redirectUrl}
            />
          )
        } else if (entry?.__typename === "BulletList") {
          return <BulletList {...entry} />
        } else if (entry?.__typename === "Podcast") {
          return <Podcast {...entry} theme="embedded" />
        } else if (entry?.__typename === "Map") {
          return <GoogleMap {...entry} />
        } else if (entry?.__typename === "Video") {
          return <Video {...entry} />
        } else if (entry?.__typename === "NtMergetag") {
          return <MergeTag id={entry.ntMergetagId} fallback={entry.ntFallback} />
        } else {
          return null
        }
      }
    }
  }

  return (
    <div className={classes} id={anchorId}>
      <div className={styles.container}>{documentToReactComponents(content.json, options)}</div>
    </div>
  )
}

ContentBlock.propTypes = {
  /*
   * Specifies an id for the container div.
   */
  anchorId: PropTypes.string,

  /**
   * Specifies the content block background color.
   */
  backgroundColor: PropTypes.oneOf([
    "cement",
    "ice",
    "ice-light",
    "lagoon",
    "limoncello",
    "off-black",
    "white",
    "light-purple"
  ]),

  /**
   * HTML string to be rendered to the page.
   */
  content: PropTypes.object,

  /**
   * Specifies whether the content block should have rounded corners.
   * Also adds additional padding between the content and the edge of the block.
   */
  isRounded: PropTypes.bool
}

ContentBlock.defaultProps = {}

export default ContentBlock
